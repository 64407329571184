/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
// @import "~@ionic/angular/css/normalize.css";
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
//@import "~@ng-select/ng-select/themes/default.theme.css";
//@import "~@angular/material/prebuilt-themes/purple-green.css";
@import 'materials.scss';
@import '~primeicons/primeicons.css';

ion-button {
  --border-radius: 12px;
}

.corderoTitle {
  font-size: 1.4rem;
  font-weight: bold !important;
  line-height: 1.8;
  border-bottom: 2px solid var(--explor-green-color);
  width: fit-content;
}

ion-menu {
  .helloMessage {
    --color: var(--cordero-color-main-blue);
  }
}

@media screen and (min-width: 0px) {
  ion-content {
    --padding-bottom: 16px;
    --padding-end: 16px;
    --padding-start: 16px;
    --padding-top: 16px;
  }
}

// Toasts
.confToast {
  --border-color: var(--explor-green-color);
  --border-width: 2px;
  --border-style: solid;
}

.errorToast {
  --border-color: var(--ion-color-danger);
  --border-width: 2px;
  --border-style: solid;
  z-index: 9999;
}

.warningToast {
  --border-color: var(--ion-color-warning);
  --border-width: 2px;
  --border-style: solid;
  --white-space: pre-wrap;
}

body.dark ion-datetime {
  color: white;
}

.tagParent {
  font-size: 0.7rem;
  font-weight: 600;
}

.centerInlineButton {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
}

.cardStyle {
  box-shadow: 1px 1px 5px var(--ion-color-medium);
  border-radius: 15px;
  padding: 10px;
  tr {
    color: var(--ion-text-color);
  }
}

.cdr-no-grow {
  flex-grow: 0;
}

@media only screen and (min-width: 768px) {
  .desktopBiggerModal {
    --width: 80%;
    --height: 80%;
  }
}

@media only screen and (min-width: 768px) {
  .desktopMediumModal {
    --width: 75%;
    --height: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .desktopSmallerModal {
    --width: 50%;
    --height: 32%;
  }
}

@media only screen and (min-width: 768px) {
  .desktopSmallerLongModal {
    --width: 50%;
    --height: 85%;
  }
}

@media only screen and (min-width: 768px) {
  .desktopSmallestModal {
    --width: 40%;
    --height: 340px;
  }
}

@media only screen and (min-width: 768px) {
  .desktopExportModal {
    --width: 30%;
    --height: 500px;
  }
}

@media only screen and (min-width: 768px) {
  .desktopUniqueFieldModal {
    --width: 40%;
    --height: 225px;
  }
}

@media only screen and (min-width: 768px) {
  .desktopReleaseLogsModal {
    --width: 40%;
    --height: 80%;
  }
}

@font-face {
  font-family: 'SignatureFont';
  src: url('assets/fonts/JustSignature-92w7.ttf');
}

//Global grid styles
.grid {
  margin-top: 5px;

  .gridHeader {
    font-size: large;
    font-weight: bold;
    border-bottom: 1px solid var(--ion-color-medium);

    /*th{
            background-color: var(--ion-color-secondary);
            color: var( --ion-color-secondary-contrast);
        }*/
  }

  .gridRow:hover {
    background-color: var(--ion-color-medium);
  }
}

.p-datatable .p-datatable-header {
  background-color: white;
  border: none;
  padding: 0;
  border-radius: 12px;
}

ion-toolbar {
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-secondary-contrast);
}

.pageSpinner {
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}

.cardSpinner {
  width: 100px;
  height: 100px;
  top: 45%;
  left: 45%;
}

.emptyMessageCol {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.corderoDot {
  height: 25px;
  width: 25px;
  background-color: lightgreen;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.alert-button-group.sc-ion-alert-md {
  justify-content: space-evenly;
}

button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

button.alert-button.alert-button-cancel {
  border-right: 0;
}

.crd-button {
  &--secondary {
    --background: transparent;
    --color: var(--ion-color-primary);
    --box-shadow: none;
  }
}

.crd-footer {
  text-align: center;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #f5f5f5;
}

.crd-disabled {
  pointer-events: none;
  opacity: 0.6;
}
